"use strict";

const mobileNavMenu = document.querySelector('#nav-menu-mobile');

if (mobileNavMenu) {
    // Required vars for the slide out mobile nav menus
    const level1Links = mobileNavMenu.querySelectorAll('#nav-menu-mobile [data-level="1"] > li a');
    const isActiveClass = 'active-mobile-link'; // not necessary, just used as reference
    const isntVisibleClass = 'translate-x-full'; // what 'slides' the menu
    const subMenuWrapper = mobileNavMenu.querySelectorAll('.sub-menu-wrapper')[0];
    const subMenuWrapper2 = mobileNavMenu.querySelectorAll('.sub-menu-wrapper')[1];
    const listWrapper2 = mobileNavMenu.querySelector("div:nth-child(2)"); // the secondary list-wrapper 
    const listWrapper3 = mobileNavMenu.querySelector("div:nth-child(3)"); // the third list-wrapper 
            // element where the cloned menu is placed
    const backOneLevelBtns = mobileNavMenu.querySelectorAll('.back-one-level');
    
    // Waiting for parent elements to be clicked;
    // If they have a subnav menu, then clone it's assoc menu
    // and 'slide' it in from the R
    for (const level1Link of level1Links) {
      level1Link.addEventListener("click", function (e) {
        const siblingList = level1Link.nextElementSibling;
        if (siblingList) {
          // 1 
          e.preventDefault();
          // 2 
          this.classList.add(isActiveClass);
          // 3 
          const cloneSiblingList = siblingList.cloneNode(true);
          // 4 
          cloneSiblingList.style.display = 'grid';
          subMenuWrapper.innerHTML = "";
          subMenuWrapper.append(cloneSiblingList);
          // 5 
          listWrapper2.classList.remove(isntVisibleClass);
        }
      });
    }
    
    // Can't just listen for link clicks upon initial page load,
    // as the new buttons are dynamically added to the DOM
    listWrapper2.addEventListener('click', function(e) {
      const target = e.target.parentElement;
      console.log(e);
      const siblingList = target.nextElementSibling;
      if (target.tagName.toLowerCase() === "a" && siblingList) {
        // 1. Prevent link click default
        e.preventDefault();
        // 2. Add ref class to indicate we're seeing active list
        target.classList.add(isActiveClass);
        // 3. Create deep clone of the level 3 nav and make sure it's displaying
        // (set to hidden in css)
        const cloneSiblingList = siblingList.cloneNode(true);
        cloneSiblingList.style.display = 'grid';
        // 4. Empty the designated destination for the third menu and
        // pop in the contents
        subMenuWrapper2.innerHTML = '';
        subMenuWrapper2.append(cloneSiblingList);

        // 5. Slide the menu in
        listWrapper3.classList.remove(isntVisibleClass);
      }
    })
    
    // Slide 'out' the active subnav menu
    // and remove it from the wrapper element so a new
    // menu can replace it
    for (const backOneLevelBtn of backOneLevelBtns) {
      backOneLevelBtn.addEventListener("click", function () {
        // 1 
        const parent = this.closest(".list-wrapper");
        parent.classList.add(isntVisibleClass);
        // 2 
        parent.previousElementSibling
          .querySelector(".active-mobile-link")
          .classList.remove(isActiveClass);
      });
    }
}

