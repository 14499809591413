'use strict';

const popInGTP = function(gtp) {
    gtp.style['display'] = 'block';
    gtp.classList.add('!opacity-100');
}

const removeHref = function(gtp) {
    let linkTag = gtp.querySelector('a');
    console.log(linkTag);
    linkTag.removeAttribute('href');
}

const updateGTPText = function() {
    let gtp = document.querySelector('#google_translate_element');
    if (gtp) {
        gtp.querySelectorAll('span').forEach((span) => {
            if (span.textContent == "Select Language") {
                span.textContent = "English";
                popInGTP(gtp);
                removeHref(gtp);
                clearInterval();
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', function() {
    setInterval(updateGTPText, 50);
});