//---------------------------------------------
// Mostly a copy/paste of the Freeform Pro 2.0 example of AJAXifying
// forms.
// ** Added TailwindCSS classes for styling purposes
// ** Revised the function declarations to check if
//    they're already defined in the global namespace; otherwise,
//    they need to be added

//---------------------------------------------
// IMPORTANT: Each form needs the 'ajax-form' class in order to submit
// properly. Otherwise, 'ajaxifying' won't detect form.
//---------------------------------------------

var chrome = navigator.userAgent.indexOf("Chrome") > -1;
var explorer = navigator.userAgent.indexOf("MSIE") > -1;
var firefox = navigator.userAgent.indexOf("Firefox") > -1;
var safari = navigator.userAgent.indexOf("Safari") > -1;
var camino = navigator.userAgent.indexOf("Camino") > -1;
var opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;

if (typeof window.renderFormSuccess === "undefined") {
  window.renderFormSuccess = function (form) {
    var successMessage = document.createElement("div");
    successMessage.setAttribute('role', 'alert');
    successMessage.classList.add("alert", "alert-success", "form-success", "rounded-3xl",
      "grid", "p-6", "bg-neutral");

    var paragraph = document.createElement("p");
    paragraph.appendChild(
      document.createTextNode("Thank you, your inquiry has been received. We'll get back to you shortly.")
    );

    successMessage.appendChild(paragraph);

    // form.insertBefore(successMessage, form.childNodes[0]);
    form.appendChild(successMessage);

    JSfadein(successMessage, 500);
  };
}

if (typeof window.removeMessages === "undefined") {
  window.removeMessages = function (form) {
    // Remove any existing errors that are being shown
    form.querySelectorAll("ul.errors").remove();
    var fieldsWithErrors = form.querySelectorAll(".has-error");
    for (var fieldIndex = 0; fieldIndex < fieldsWithErrors.length; fieldIndex++) {
      var field = fieldsWithErrors[fieldIndex];
      field.classList.remove("has-error");
    }

    // Remove success messages
    form.querySelectorAll(".form-success").remove();
    document.getElementsByClassName("form-errors").remove();
  };
}

if (typeof window.renderErrors === "undefined") {
  /**
   * @param errors
   * @param form
   */
  window.renderErrors = function (errors, form) {
    for (var key in errors) {
      if (!errors.hasOwnProperty(key) || !key) {
        continue;
      }

      var messages = errors[key];
      var errorsList = document.createElement("ul");
      errorsList.setAttribute('role', 'alert');
      errorsList.classList.add("errors", "help-block", "text-red-900", "pt-1");
 
      for (var messageIndex = 0; messageIndex < messages.length; messageIndex++) {
        var message = messages[messageIndex];
        var listItem = document.createElement("li");
        listItem.appendChild(document.createTextNode(`Error: ${message}`));
        errorsList.appendChild(listItem);
      }

      var inputList = form.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']");
      for (var inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
        var input = inputList[inputIndex];

        input.parentElement.classList.add("has-error");
        input.parentElement.appendChild(errorsList);
      }
    }
  };
}

if (typeof window.renderFormErrors === "undefined") {
  window.renderFormErrors = function (errors, form) {
    var errorBlock = document.createElement("div");
    errorBlock.setAttribute('role', 'alert');
    errorBlock.classList.add("alert", "alert-danger", "form-errors");

    var paragraph = document.createElement("p");
    paragraph.classList.add("text-red-900");
    paragraph.appendChild(document.createTextNode("This form has errors."));
    errorBlock.appendChild(paragraph);

    if (errors.length) {
      var errorsList = document.createElement("ul");
      errorsList.setAttribute('role', 'alert');

      for (var messageIndex = 0; messageIndex < errors.length; messageIndex++) {
        var message = errors[messageIndex];
        var listItem = document.createElement("li");
        listItem.classList.add("text-red-900");
        listItem.appendChild(document.createTextNode(message));
        errorsList.appendChild(listItem);
      }

      errorBlock.appendChild(errorsList);
    }

    form.insertBefore(errorBlock, form.childNodes[0]);
  };
}

function JSfadein(el, time) {
  el.style.opacity = 0;
  var last = +new Date();

  var tick = function () {
    el.style.opacity = +el.style.opacity + (new Date() - last) / time;
    last = +new Date();

    if (+el.style.opacity < 1) {
      (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
    }
  };
  tick();
}

//---------------------------------------------
// IMPORTANT: Each form needs the 'ajax-form' class in order to submit
// properly. Otherwise, 'ajaxifying' won't detect form.
//---------------------------------------------
function lookForFormsToAjaxify() {
  var forms = document.getElementsByTagName("form");
  forms = Array.from(forms).filter(form => form.classList.contains("ajax-form"));

  for (var formIndex = 0; formIndex < forms.length; formIndex++) {
    var form = forms[formIndex];

    if (!form.dataset.ajaxified) {
      form.dataset.ajaxified = true;
      form.addEventListener("submit", ajaxifyForm, false);
    }
  }
}


function ajaxifyForm(event) {
  var form = event.target;
  var data = new FormData(form);
  var request = new XMLHttpRequest();


  // TODO: make sure you set 'disable' of the submit button
  // within Freeform settings to false; if you have multiple
  // forms on a page, this will break the un-disabling of the forms on submit
  let submitBtn = form.querySelector('button[type="submit"]');
  if (submitBtn) {
    submitBtn.setAttribute('disabled', '');
  } 

  if (safari) {
    for (var i = 0; i < form.elements.length; i++) {
      if (form.elements[i].type == "file") {
        if (form.elements[i].value == "") {
          var elem = form.elements[i];
          data.delete(elem.name);
        }
      }
    }
  }

  var method = form.getAttribute("method");
  var action = form.getAttribute("action");

  request.open(method, action ? action : window.location.href, true);
  request.setRequestHeader("Cache-Control", "no-cache");
  request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
  request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");
  // request.setRequestHeader('Content-type', 'application/json');
  request.onload = function () {
    removeMessages(form);

    if (request.status === 200) {
      console.log(request.response);
      var response = JSON.parse(request.response);

      if (response.success && response.finished) {
        // Reset the form so that the user may enter fresh information
        form.reset();
        console.log(response);

        // ============================================================
        // Uncomment this to have the form redirect to the success page
        // ============================================================
        // if (response.returnUrl) {
        //   window.location.href = response.returnUrl;
        // }

        renderFormSuccess(form);

      } else if (response.errors || response.formErrors) {
        console.log(response);

        renderErrors(response.errors, form);
        renderFormErrors(response.formErrors, form);
      }

      // if (response.honeypot) {
      //   console.log(`Honeypot: ${response.honeypot}`);
      //       var honeypotInput = form.querySelector("input[name^=freeform_form_handle_]");
      //       honeypotInput.setAttribute("name", response.honeypot.name);
      //       honeypotInput.setAttribute("id", response.honeypot.name);
      //       honeypotInput.value = response.honeypot.hash;
      //   }

      unlockSubmit(form);
    } else {
      console.error(`Request: ${request}`);
    }

    unlockSubmit(form);
  };

  request.send(data);
  event.preventDefault();
}

function loadExternalForm(url, targetElement) {
  var request = new XMLHttpRequest();

  // Load the forms content into the #form-loader div
  request.open("GET", url, true);
  request.send();
  request.onload = function () {
    if (request.status === 200) {
      targetElement.innerHTML = request.response;

      // Activate all of the loaded scripts
      var scripts = targetElement.querySelectorAll("script");
      for (var index = 0; index < scripts.length; index++) {
        var script = scripts[index];
        var newScript = document.createElement("script");
        newScript.innerHTML = script.innerHTML;
        targetElement.appendChild(newScript);

        script.parentNode.removeChild(script);
      }

      lookForFormsToAjaxify();
    } else {
      console.error(request);
    }
  };
}

/**
 * Remove the "disabled" state of the submit button upon successful submit
 *
 * @property form
 */
function unlockSubmit(form) {
  form.querySelector("button[type=submit]").removeAttribute("disabled");
  if (typeof grecaptcha !== "undefined") {
    grecaptcha.reset();
  }
}

// Add remove prototypes
Element.prototype.remove = function () {
  this.parentElement.removeChild(this);
};

NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
  for (var i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i]);
    }
  }
};

lookForFormsToAjaxify();
