'use strict';
import {
    Fancybox,
    Carousel
} from "@fancyapps/ui";
import {
    Autoplay
} from "@fancyapps/ui/dist/carousel.autoplay.esm";
Carousel.Plugins.Autoplay = Autoplay;

// Panzoom also available *

// *******************************************
// NOTE: These commented Carousels are from the demo in components/fancyapps-slider-with-lightbox
// *******************************************
// if (document.querySelectorAll("#mainCarousel.carousel").length > 0) {
//   Initialise Carousel
//   const mainCarousel = new Carousel(document.querySelector("#mainCarousel"), {
//     Dots: true,
//     Navigation: true,
//     slidesPerPage: 1,
//   });

//   Thumbnails
//   const thumbCarousel = new Carousel(document.querySelector("#thumbCarousel"), {
//     Sync: {
//       target: mainCarousel,
//       friction: 0
//     },
//     Dots: false,
//     Navigation: false,
//     center: true,
//     slidesPerPage: 1,
//     infinite: false
//   });

//   Customize Fancybox
//   Fancybox.bind('[data-fancybox="gallery"]', {
//     Carousel: {
//       on: {
//         change: that => {
//           mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
//             friction: 0
//           });
//         }
//       }
//     }
//   });
// } 
if (document.querySelectorAll(".homepage-slideshow").length > 0) {
    const homepageCarousel = new Carousel(document.querySelector(".homepage-slideshow"), {
		Dots: true,
		Navigation: {
			prevTpl: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.798" height="11.062" viewBox="0 0 15.798 11.062">
				<defs>
				  <clipPath>
					<rect data-name="Rectangle 23" width="15.798" height="11.062" fill="#ffffff"/>
				  </clipPath>
				</defs>
				<g data-name="Group 277" transform="translate(15.798 11.062) rotate(180)">
				  <g data-name="Group 275" clip-path="url(#clip-path)">
					<path data-name="Path 196" d="M15.618,5.1,10.7.18a.614.614,0,1,0-.869.869L13.7,4.916H.615a.615.615,0,1,0,0,1.229H13.7L9.833,10.013a.614.614,0,1,0,.869.869l4.916-4.916a.614.614,0,0,0,0-.869" fill="#ffffff"/>
				  </g>
				</g>
			  </svg>`,
			nextTpl: `<svg data-name="Group 276" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.798" height="11.062" viewBox="0 0 15.798 11.062">
				<defs>
				  <clipPath>
					<rect data-name="Rectangle 23" width="15.798" height="11.062" fill="#ffffff"/>
				  </clipPath>
				</defs>
				<g data-name="Group 275" clip-path="url(#clip-path)">
				  <path data-name="Path 196" d="M15.618,5.1,10.7.18a.614.614,0,1,0-.869.869L13.7,4.916H.615a.615.615,0,1,0,0,1.229H13.7L9.833,10.013a.614.614,0,1,0,.869.869l4.916-4.916a.614.614,0,0,0,0-.869" fill="#ffffff"/>
				</g>
			  </svg>`,
		  },
        slidesPerPage: 1,
        infinite: true,
        fill: true,
        Autoplay: true,
    }, {
        Autoplay
    });

}

if (document.querySelectorAll(".about-slideshow").length > 0) {
    const aboutCarousel = new Carousel(document.querySelector(".about-slideshow"), {
        Dots: true,
        slidesPerPage: 1,
        infinite: true,
        fill: false,
        Autoplay: true,
    }, {
        Autoplay
    });
}

// PDP hero slideshow
if (document.querySelectorAll(".product__intro-slideshow").length > 0) {
    const productHeroCarousel = new Carousel(document.querySelector(".product__intro-slideshow"), {
        Dots: true,
        slidesPerPage: 1,
        infinite: false,
        fill: true,
        Navigation: {
			prevTpl: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.798" height="11.062" viewBox="0 0 15.798 11.062">
				<defs>
				  <clipPath>
					<rect data-name="Rectangle 23" width="15.798" height="11.062" fill="#241F21"/>
				  </clipPath>
				</defs>
				<g data-name="Group 277" transform="translate(15.798 11.062) rotate(180)">
				  <g data-name="Group 275" clip-path="url(#clip-path)">
					<path data-name="Path 196" d="M15.618,5.1,10.7.18a.614.614,0,1,0-.869.869L13.7,4.916H.615a.615.615,0,1,0,0,1.229H13.7L9.833,10.013a.614.614,0,1,0,.869.869l4.916-4.916a.614.614,0,0,0,0-.869" fill="#241F21"/>
				  </g>
				</g>
			  </svg>`,
			nextTpl: `<svg data-name="Group 276" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.798" height="11.062" viewBox="0 0 15.798 11.062">
				<defs>
				  <clipPath>
					<rect data-name="Rectangle 23" width="15.798" height="11.062" fill="#241F21"/>
				  </clipPath>
				</defs>
				<g data-name="Group 275" clip-path="url(#clip-path)">
				  <path data-name="Path 196" d="M15.618,5.1,10.7.18a.614.614,0,1,0-.869.869L13.7,4.916H.615a.615.615,0,1,0,0,1.229H13.7L9.833,10.013a.614.614,0,1,0,.869.869l4.916-4.916a.614.614,0,0,0,0-.869" fill="#241F21"/>
				</g>
			  </svg>`,
		  },
    });

    Fancybox.bind(`[data-fancybox="gallery"]`, {
        Toolbar: {
            display: [{
                    id: "prev",
                    position: "center"
                },
                {
                    id: "counter",
                    position: "center"
                },
                {
                    id: "next",
                    position: "center"
                },
                "zoom",
                "slideshow",
                "fullscreen",
                "download",
                "thumbs",
                "close",
            ],
        },
        Carousel: {
            on: {
                change: (that) => {
                    // Sync Carousel slide
                    productHeroCarousel.slideTo(productHeroCarousel.findPageForSlide(that.page), {
                        friction: 0,
                    });
                },
            },
        }
    });
}