"use strict";

// We're preventing the expansion of the navbar if there's also an anchor bar on the page
import {CONDENSED_NAV_HEIGHT} from "./config";
import {urlPathArray} from "./utilities";

const nav = document.querySelector('#main-nav');

if (nav) {
  const topNavBar = nav.querySelector('#topNavBar');
  const mobileNavMenu = nav.querySelector('#nav-menu-mobile');
  
  const body = document.querySelector('body');
  const toggleNavBtn = topNavBar.querySelector("#toggle-nav-btn");
  const mainContent = document.querySelector('[role="main"]');
  
  // Helps us to 'lock' the window's scrolled position when toggling the fixed overlay
  // nav menu; otherwise, it'll shoot the user back to the top of the page every time
  let topScrollPosn;
  
  // *** HOMEPAGE and ABOUT PAGE ***
  //---------------------------------------------
  // Nav scrolling animation - hides on scroll down,
  // reveal on scroll up
  // ** Preventing this behavior (to expand back to full height) on pages with an anchored nav bar **
  //---------------------------------------------

if ((urlPathArray[1] === '' && document.querySelector('body').classList.contains('homepage')) ||
  (urlPathArray[2] === '' && document.querySelector('body').classList.contains('about'))) {
  console.log(urlPathArray);
  const navScrollAnim = gsap.from(topNavBar, { 
    height: CONDENSED_NAV_HEIGHT,
    paused: true,
    duration: 0.1,
  }).progress(1);
   
  ScrollTrigger.create({
    start: "top top",
    end: 99999,
    onUpdate: (self) => {
        //   When scrolling up, reveal the full height of nav.
        //   Otherwise, condense the height while scrolling down.
        if (self.direction === -1) {
          navScrollAnim.play();
          topNavBar.classList.add('fullyOpen');
        } else {
          navScrollAnim.reverse();
          topNavBar.classList.remove('fullyOpen');
        }
    }
  });
}
  
  
  //---------------------------------------------
  // Activates ability for mobile overlay navigation,
  // Blurs page when mobile nav is opened,
  // Locks scroll position when mobile nav is opened,
  // Activates toggle of mobile nav functionality on button click
  //---------------------------------------------
  const initNav = function() {
  //---------------------------------------------
  // When the overlay menu opens, need to maintain scroll position
  // without jumping to the top of the viewport (because of position:fixed)
  // being set
  // NOTE: The execution order within the conditionals matters:
  // "For the scrollTo() method to work, the document must be larger than the screen, and the scrollbar must be visible."
  //---------------------------------------------
  function maintainScrolledScreenPosition() {
    if (!body.classList.contains('noscroll')) {
      topScrollPosn = window.scrollY;
      body.style.top = `${-topScrollPosn}px`;
      body.classList.toggle('noscroll');
    } else {
      body.classList.toggle('noscroll');
      window.scrollTo(0, topScrollPosn);
      body.style.top = '';
    }
  }
  
  //---------------------------------------------
  // Toggle the blur of the div.main content when the
  // nav menu is opened/closed; slide the menu
  // out from the right side
  //---------------------------------------------
    const toggleMobileNavMenu = function() {
      toggleNavBtn.classList.toggle("open");
      nav.classList.toggle("open");
      mobileNavMenu.classList.toggle('open');
  
      if (toggleNavBtn.getAttribute('aria-expanded') == 'false') {
        toggleNavBtn.setAttribute('aria-expanded', 'true');
        toggleNavBtn.setAttribute('aria-hidden', 'false');
      } else {
        toggleNavBtn.setAttribute('aria-expanded', 'false');
        toggleNavBtn.setAttribute('aria-hidden', 'true');
      }
        
      mainContent.classList.toggle('blurred');
      if (mainContent.getAttribute('aria-hidden') == 'true') {
        mainContent.setAttribute('aria-hidden', 'false'); 
      } else {
        mainContent.setAttribute('aria-hidden', 'true'); 
      }
  
      maintainScrolledScreenPosition();
    }
  
    //---------------------------------------------
    // Open nav drawer on button click
    //---------------------------------------------
    toggleNavBtn.addEventListener("click", function () {
        toggleMobileNavMenu();
      }, {passive: true});
  } 
  
  window.addEventListener("load", function () {
    initNav();
  }, {passive: true});
  
}
