'use strict';

import { urlPathArray } from "./utilities";
import { createDOMElFromData, createParentURItoAppendToLinks } from "./utilities";

const categorySelector = document.querySelector('#news-category-select');
console.log('Category Selector:', categorySelector);
const newsItemListTarget = document.querySelector('.news-press__list-container');
console.log('News Item List Target:', newsItemListTarget);
const newsItemCatLinks = document.querySelectorAll('[data-link-type="news-press-cat"]');
const defaultMessage = 'Please select a category above.';


const displayDefaultMessage = function() {
    newsItemListTarget.innerHTML = '';
    const parser = new DOMParser();
    let messageToShow = parser.parseFromString(`<p>${defaultMessage}</p>`, 'text/html');
    messageToShow = messageToShow.querySelector('body').firstChild;
    newsItemListTarget.insertAdjacentElement('beforeend', messageToShow);
    newsItemListTarget.classList.add('opacity-1');
}

const handleCategoryChange = async function(categorySelectorEl) {
    console.log('Category Selector:', categorySelectorEl);
    try {
        const newsItemsData = await fetchURLDataNews(categorySelectorEl);
        const updatedNewsItemsHTML = await adjustPostLinks(newsItemsData);
    
        if (updatedNewsItemsHTML.length > 0 && updatedNewsItemsHTML != '') {
            appendData(updatedNewsItemsHTML, newsItemListTarget);
        }
    } catch(err) {
        newsItemListTarget.classList.remove('opacity-0')
        displayDefaultMessage();
    }

}

//---------------------------------------------
// update the URLs to the pages for the News Item posts
//---------------------------------------------
const adjustPostLinks = async function(newsItemsData) {
    const parentPathStr = await createParentURItoAppendToLinks(newsItemsData.parentURIPath);
    const domData = await createDOMElFromData(newsItemsData.data, parentPathStr);
    return domData;
}


//---------------------------------------------
// Fetches all of the HTML to populate all of the
// selected category News Items
//---------------------------------------------
const fetchURLDataNews = async function(catSelector) {
    let selectedOption;
    // Either an <a></a> or a <select></select>
    if (catSelector.nodeName.toLowerCase() == 'select') {
        selectedOption = catSelector.options[catSelector.selectedIndex];
    } else if (catSelector.nodeName.toLowerCase() == 'a') {
        selectedOption = catSelector;
    }

    let catID = selectedOption.dataset.catId;
    let $href = selectedOption.dataset.templatePath;
    
    if (catID == 0) {
        throw new Error('Select category is selected');
    }
    let $url = $href + '/' + catID;
    console.log('URL:', $url);

    // If the Item's page is a 2ndary child page (so url-title is 3rd segment),
    // set the appropriate parent URL for the entry's hrefs 
    let parentURIPath;
    if (urlPathArray.length == 4) {
        parentURIPath = [urlPathArray[1].toString(), urlPathArray[2].toString()];
    } else if (urlPathArray.length == 3) {
        parentURIPath = [urlPathArray[1].toString()];
    }

    

    const response = await fetch($url);
    const data = await response.text();
    console.log('Response: ', response);
    console.log('Data:', data);
    return {data, parentURIPath};
}

const redisplayEventList = function() {
    newsItemListTarget.classList.remove('opacity-0');
}

const appendData = function (dataToAppend) {
    newsItemListTarget.innerHTML = '';
    newsItemListTarget.insertAdjacentHTML('beforeend', dataToAppend);
    redisplayEventList();
}


if (categorySelector && newsItemListTarget) {
    categorySelector.addEventListener('change', function(e) {
        newsItemListTarget.classList.add('opacity-0');
        handleCategoryChange(categorySelector);
    })
}

if (newsItemCatLinks.length > 0) {
    newsItemCatLinks.forEach(catLink => {
        catLink.addEventListener('click', function(e) {
            e.preventDefault();
            newsItemListTarget.classList.add('opacity-0');
            // TODO: Still need to add event listeners to newly loaded items 
            handleCategoryChange(e.currentTarget);
        })
    })
}