'use strict';

//---------------------------------------------
// Get URL segments
//---------------------------------------------
export const urlPathArray = window.location.pathname.split('/');

export const getBaseUrl = window.location.origin;

//---------------------------------------------
// Create a DOM object to loop through
// so that the hrefs in the returned listings can be updated
// to match proper parent URI
//---------------------------------------------
export const createDOMElFromData = async function(htmlAsString, parentURI) {
    const parser = new DOMParser();
    const htmlToUpdate = htmlAsString.trim();

    // Create DOM obj
    const returnedHTML = parser.parseFromString(htmlToUpdate, "text/html");

    // Update the hrefs in the news/events entries to use correct parent URL
    const innerHTMLToAppend = await changeLinkHrefs(returnedHTML, parentURI);

    return innerHTMLToAppend.querySelector('body').innerHTML;
}

//---------------------------------------------
// Creates the correct parent URI to swap into
// hrefs for the ajax loaded news/press/events entries
// in their links
//---------------------------------------------
export const createParentURItoAppendToLinks = async function(parentURIArr) {
    const [segment_1, segment_2] = parentURIArr;
    if (segment_1 && segment_2) {
        return `/${segment_1}/${segment_2}`;
    } else if (segment_1) {
        return `/${segment_1}`;
    } else {
        throw new Error('Issue with parent URI');
    }
}

//---------------------------------------------
// Update the link hrefs to match parent URL properly
// based on news page structure (parent or no parent)
//---------------------------------------------
export const changeLinkHrefs = async function(stringOfHTML, parentURI) {
    const linksInNewListings = stringOfHTML.querySelectorAll('a');
    linksInNewListings.forEach(link => {
        const linkType = link.dataset.linkType;
        let linkLoc = link.attributes.href.value;
        const [linkPageURI] = linkLoc.split("/").slice(-1);
        if (linkType == 'news-press-cat') {
            link.attributes.href.value = `${parentURI}/all`;
        } else if (linkType == 'news-post' && linkPageURI) {
            link.attributes.href.value = `${parentURI}/${linkPageURI}`;
        }
    });
    return stringOfHTML;
}

// const fs = require('fs');

// const content = 'Some content!';

// fs.writeFile('/Users/joe/test.txt', content, err => {
//   if (err) {
//     console.error(err);
//   }
//   // file written successfully
// });