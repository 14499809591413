'use strict';

import { prefersReducedMotionMediaQueryDetected } from "./config";

const textSectionsToReveal = [
  '.about-banner__secondary-tagline *',
  '.contact-info__container > *',
  '.contact-form__container > *',
  '.office-team__container > *',
  '.sales-team__container > *',
  '[data-block-name="basic_copy_row"',
  '[data-block-name="centered_copy_row"] *', 
  '[data-block-name="copy_item"] *', 
  '[data-block-name="image_item"] *', 
  '[data-block-name="full_copy_row"]',
  '[data-block-name="product_description"]',
  '.product__specs-and-downloads-container'
];
const columnSectionsToRevealWithStagger = document.querySelectorAll(
  `[data-block-name="feature_item"], 
    .event__item, .news-press__item, 
    .product_accessory-container,
    .addl-product-accessory-item`
  );
const homepageBody = document.querySelector('body.homepage');


//---------------------------------------------
// Specific elements to target delayed reveals for Homepage only
//---------------------------------------------
// const doHomepageScrollReveals = function() {
//   const hpHeroBanner = document.querySelector('#hp_banner_section');
//   // const hpHeroBannerIntro = hpHeroBanner.querySelector('.intro');
//   // const hpHeroBannerCopy = hpHeroBanner.querySelector('.copy');
//   const hpHeroBannerIntroHeading = hpHeroBanner.querySelector('h1');
  
//   //---------------------------------------------
//   // Transition reveal of h1 in Intro Section on Homepage
//   //---------------------------------------------
//   let tl = gsap.timeline({defaults: {ease: "power1.out", duration: 0.6}});
  
//   if (hpHeroBannerIntroHeading) {

//     tl.from(hpHeroBannerIntroHeading, {
//       opacity: 0,
//       y: '20%', // 20% of the height of the element
//       delay: 0.75,
//     });
//   }
// }

//---------------------------------------------
// Stagger slide up and opacity reveal of Copy Columns Row items
// NOTE: 'autoAlpha' = opacity almost; see docs https://greensock.com/docs/v3/GSAP/CorePlugins/CSSPlugin
//---------------------------------------------
const doStaggeredReveals = function() {
  gsap.set(columnSectionsToRevealWithStagger, {y: 20, autoAlpha: 0});
  ScrollTrigger.batch(columnSectionsToRevealWithStagger, {
    onEnter: batch => gsap.to(batch, {
      autoAlpha: 1, 
      stagger: 0.3, 
      y: 0
    }),
    start: "20px bottom",
    end: "top top"
  });
}

// window.addEventListener('load', function() {
//   if (homepageBody && !prefersReducedMotionMediaQueryDetected) {
//     doHomepageScrollReveals();
//   }
// })

if (columnSectionsToRevealWithStagger.length > 0 && !prefersReducedMotionMediaQueryDetected) {
  doStaggeredReveals();
}

if (!prefersReducedMotionMediaQueryDetected) {

  // Text elements to reveal and slide up on scroll trigger
  const textElsToReveal = gsap.utils.toArray(textSectionsToReveal);

  textElsToReveal.forEach(el => {
    gsap.from(el, {
      opacity: 0,
      y: '10%',
      ease: "power1.out", 
      scrollTrigger: {
        trigger: el,
        start: "top 80%",
        once: true,
      }
    });
  });

  // Images rising on scroll trigger, images fading in on scroll trigger
  const imgsToReveal = gsap.utils.toArray("picture:has(img:not(.dont-animate))");
  imgsToReveal.forEach((img) => img.style.opacity = '0');
  imgsToReveal.forEach(img => {
    gsap.to(img, {
      opacity: 1,
      ease: "power1.out", 
      duration: .25,
      scrollTrigger: {
        trigger: img,
        start: "top 80%",
        once: true, 
        // markers:true,
      }
    });
  });
  imgsToReveal.forEach(img => {
    gsap.from(img, {
      y: '10%',
      ease: "power1.out", 
      duration: 1,
      scrollTrigger: {
        trigger: img,
        start: "top 80%",
        end: "top 80%",
        once: true,
        // markers: true,
      }
    });
  });

}
