"use strict";
import Accordion from 'accordion-js';

// ACCORDION JS
if (document.querySelectorAll('.accordion-container').length > 0) {
  
  // const accordionParents = document.querySelectorAll('.accordions-parent-container');
  const accordions = Array.from(document.querySelectorAll('.accordion-container'));

  // Initialize all page's accordions
  accordions.forEach((accordionEl) => {
    const accInitiated = new Accordion(accordionEl, {
      duration: 400,
      showMultiple: true,
    });
  });
}

