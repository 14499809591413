'use strict';

import { urlPathArray } from "./utilities";

import { createDOMElFromData, createParentURItoAppendToLinks } from "./utilities";

//---------------------------------------------
// *********
// This accounts for all of the 'events' or 'news' things on the site:
//  Two channels: Events, News & Press
// UPDATE: Changed to ONE channel, News Item
// 
//---------------------------------------------

// This btn class is shared between all of the 'load more' btns!
let loadMoreEventsNewsPressBtn = document.querySelector('.events-btn-link:not(.view-all-events-btn-link)');

//---------------------------------------------
// Once loaded the content on the page, update which
// btn is loading more content
//---------------------------------------------
const switchLoadMoreBtn = function(btnClicked) {
    btnClicked.remove();
    // REDEFINE the load more content btn
    loadMoreEventsNewsPressBtn = document.querySelector('.events-btn-link');
    if (loadMoreEventsNewsPressBtn) {
        loadMoreEventsNewsPressBtn.addEventListener('click', handleBtnClick);
    }
}

//---------------------------------------------
// 1. Takes all of the retrieved data and puts into DOM
// 2. Remove the old 'load more' btn and listen for click
//      on the new btn
//---------------------------------------------
const handleAndAppendData = function(returnedData, btnClicked, appendTarget) {
    // let DOMDataToAppend = returnedData.data;
    // this is /about/whats-happening (two segments in URL)
    if (returnedData.parentURIPath.length) {
        createParentURItoAppendToLinks(returnedData.parentURIPath).then(parentURI => {
            // Trim the received HTML string and create DOM el to loop through
            // then fix the link paths
            return createDOMElFromData(returnedData.data, parentURI);
        }).then((DOMDataToAppend) => {
            // append the fixed HTML to the listings area
            appendTarget.insertAdjacentHTML('beforeend', DOMDataToAppend);
            // and make sure the newest 'load more' btn is displaying
            switchLoadMoreBtn(btnClicked);
        }).catch(err => {
            console.log(`An error occured!: ${err.message}`);
        });
    // if links are just /whats-happening, still need to update the
    // links with correct parent URI
    } else {
        throw new Error(`Problem encountered with parent URI`);
    }
}

//---------------------------------------------
// Handle the click of the load btn:
// 1. ID the target of the click
// 2. Check the location of this btn (which page we're on)
// 3. Define the target for the newly incoming data
// 4. Call the load fn
//---------------------------------------------
const handleBtnClick = function(e) {
    let loadBtn = e.currentTarget;
    loadBtn.classList.add('opacity-0');
    let appendTarget = document.querySelector(loadBtn.getAttribute('data-target'));

    fetchURLData(loadBtn)
        .then(returnedData => {
        handleAndAppendData(returnedData, loadBtn, appendTarget);
        console.log('Returned Data: ', returnedData);
        console.log('Load Button:', loadBtn);
        console.log('Append Target:', appendTarget);
    }).catch(err => alert(`Issue encountered: ${err.message}`));
}

//---------------------------------------------
// Fetches all of the HTML to populate the next page
// of Event items
//---------------------------------------------
const fetchURLData = async function(btnClicked) {
    let $href = btnClicked.getAttribute("href"),
    $url,
    $load = btnClicked.getAttribute("data-load"),
    $page_number = $href.slice($href.lastIndexOf("/") + 1);
    
    $url = $load + '/' + $page_number;

    // If the News page is a child page,
    // set the appropriate parent URL for the 
    // entry's hrefs 
    let parentURIPath;
    if (urlPathArray.length == 4) {
        parentURIPath = [urlPathArray[1].toString(), urlPathArray[2].toString()];
    } else if (urlPathArray.length == 3) {
        parentURIPath = [urlPathArray[1].toString()];
    }

    const response = await fetch($url);
    const data = await response.text();
    return {data, parentURIPath};
}
    
//---------------------------------------------
// If we're on the 'All Events' page, listen for the load
// button click
//---------------------------------------------
if (loadMoreEventsNewsPressBtn && loadMoreEventsNewsPressBtn.getAttribute('data-load')) {
    loadMoreEventsNewsPressBtn.addEventListener('click', function(e) {
        handleBtnClick(e);
    });
}
