import smoothscroll from "smoothscroll-polyfill";

const fs = require('fs');


// import 'tw-elements';
// import '../node_modules/lite-youtube-embed/src/lite-yt-embed.js';
// import '@slightlyoff/lite-vimeo';

// import Accordion from 'accordion-js'; ** Imported in accordions.js file

// Base level imports
 
// IMPORT CONSTANT variables
import './components/utilities.js';  
import './components/config.js'; 
 
import "./components/cookie.js"; // for Notice message option  



// ****************************************
import "./components/google-translate-plugin-hack.js"; // Google Translate plugin
 
// Modified from base level 'imports'
import "./components/navigation.js"; 
import "./components/mobile-navigation-slideout-menu.js"; 
// import "./components/tiny-sliders.js";
import "./components/fancybox.js";
import "./components/accordions.js"; 
// import "./components/tabs.js";

import "./components/scroll-animations.js";
//import "./components/video-autoplay-low-power-mode.js";

import "./components/load-more-news-press-events.js"; 
import "./components/filter-news-items.js";

import "./components/contact-form-submit-with-ajax.js";
//import "./components/find-a-dealer.js";

// import "./components/google-locator.js";

import "./styles/main.scss";

smoothscroll.polyfill();

// const content = 'Some content!';

// fs.writeFile('/Users/joe/test.txt', content, err => {
//   if (err) {
//     console.error(err);
//   }
//   // file written successfully
// });